<template>
  <div>
    <label :for="id">{{ label }}</label>
    <div class="input-icon">
      <svg class="icon icon-small icon-single-neural">
        <use :xlink:href="`/images/icon-lib.svg#${icon}`" />
      </svg>
      <input
        :type="type"
        class="form-control"
        :value="value"
        @input="$emit('input',$event.target.value)"        
        :placeholder="placeholder"
        :id="id"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],

    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
  },

  data: function () {
    return {};
  },
};
</script>

<style lang="scss" scoped>

@import "@/styles/_variables.scss";

.form-control {
  display: block;
  width: 100%;
  //height: calc(1.5em + 0.75rem + 2px);
  padding: 0.688rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #808495;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
}

.input-icon {
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
  }

  input {
    padding-left: 3rem;
  }
}


  input[type='date']:focus:not([readonly]),
  input[type='datetime-local']:focus:not([readonly]),
  input[type='email']:focus:not([readonly]),
  input[type='number']:focus:not([readonly]),
  input[type='password']:focus:not([readonly]),
  input[type='search-md']:focus:not([readonly]),
  input[type='search']:focus:not([readonly]),
  input[type='tel']:focus:not([readonly]),
  input[type='text']:focus:not([readonly]),
  input[type='time']:focus:not([readonly]),
  input[type='url']:focus:not([readonly]){    
    border: 1px solid $blue-500;
    box-shadow: 0 1px 0 0 $gray-300;
  }

.form-control--error {
  label{
    color: $red-500;
  }
  .form-control {
    border: 1px solid $red-500 !important;
  }
}



</style>